import * as THREE from 'three';
import {SCENE_DOM_NAME} from "@/components/ThreeDCanvas/SceneConfig/SceneConstants";
import {WebGLRenderer, sRGBEncoding, ACESFilmicToneMapping, VSMShadowMap} from "three";

export class RendererConfig {

  constructor() {
    this.rendererSetup()
  }

  rendererSetup(){
    let renderer = new WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.outputEncoding = sRGBEncoding;
    renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.2;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = VSMShadowMap;

    const container = document.getElementById( SCENE_DOM_NAME );
    container.appendChild( renderer.domElement );

    this.renderer = renderer
  }
  setSize(width, height){
    this.renderer.setSize( width, height );
  }

}
