import THREE from "three";
import {ShadowMapViewer} from "three/examples/jsm/utils/ShadowMapViewer";
import {AmbientLight, SpotLight, BoxGeometry, ShadowMaterial, Mesh} from "three";


export class LightningConfig{
  lightIntensity = 0.6;

  ambientLight = new AmbientLight( 0xffffff , 0.8)
  spotLight = new SpotLight( 0xffffff );
  spotAmbientLights = [];
  ground = {}

  constructor() {
    this.lightningSetup();
    window.addEventListener('resize', ()=>{
      this.resizeShadowMapViewers();
      this.spotLightShadowMapViewer.updateForWindowResize();
    })
  }

  completeLightning(){
    return {
      ambientLight: this.ambientLight,
      spotLight: this.spotLight,
      spotAmbientLights: this.spotAmbientLights,
      ground: this.ground
    }
  }

  spotLightSetup(){
    this.spotLight.name = 'Spot Light';
    this.spotLight.angle = Math.PI / 5;
    this.spotLight.penumbra = 0.3;
    this.spotLight.position.set( 1250, 2500, 1250 );
    this.spotLight.castShadow = true;
    this.spotLight.shadow.camera.near = 2000;
    this.spotLight.shadow.camera.far = 7500;
    this.spotLight.shadow.mapSize.width = 1024;
    this.spotLight.shadow.mapSize.height = 1024;

    this.spotLightShadowMapViewer = new ShadowMapViewer( this.spotLight );
    this.resizeShadowMapViewers();
  }

  ambientLightsSetup(){
    let lightList = [
      {
        name:'Spot Light1',
        position: {
          x:-7500,
          y: 5000,
          z:-7500
        }
      },
      {
        name:'Spot Light2',
        position: {
          x: 7500,
          y: 5000,
          z: 7500
        }
      },
      {
        name:'Spot Light3',
        position: {
          x:-7500,
          y: 5000,
          z: 7500
        }
      },
      {
        name:'Spot Light4',
        position: {
          x: 7500,
          y: 5000,
          z:-7500
        }
      }];
    let spotAmbientLights = [];

    for (let light of lightList){
      const spotLightAmb = new SpotLight( 0xffffff, this.lightIntensity );
      spotLightAmb.name = light.name;
      spotLightAmb.position.set( light.position.x, light.position.y, light.position.z );
      spotLightAmb.castShadow = true;
      spotLightAmb.shadow.camera.near = 10000;
      spotLightAmb.shadow.camera.far = 7500;
      spotLightAmb.shadow.mapSize.width = 1024;
      spotLightAmb.shadow.mapSize.height = 1024;

      spotAmbientLights.push(spotLightAmb)
    }
    this.spotAmbientLights = spotAmbientLights
  }

  groundSetup(){
      const geometry = new BoxGeometry( 25000, 0.001, 25000 );
      const material = new ShadowMaterial( {
        opacity:0.2,
      } );
      material.transparent = true;

      const ground = new Mesh( geometry, material );
      ground.position.y=-1300;
      ground.scale.multiplyScalar( 3 );
      ground.name = 'Floor-shadow-plane'
      ground.castShadow = false;
      ground.receiveShadow = true;

      this.ground = ground
  }

  lightningSetup(){

    this.spotLightSetup()
    this.ambientLightsSetup()
    this.groundSetup()
  }

  resizeShadowMapViewers(){
    const size = window.innerWidth * 37.5;
    this.spotLightShadowMapViewer.size.set( size, size );
    this.spotLightShadowMapViewer.position.set( size + 5000, 2500 );
  }
}
