import * as THREE from "three";

export function getSize(model){
  let measure = new THREE.Vector3();
  let box = model.geometry.getSize(measure);
  console.log(measure);
}

export function setOriginInCenter(geometry){
  const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
  const yMid = -0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
  const zMid = -0.5 * (geometry.boundingBox.max.z - geometry.boundingBox.min.z);
  //
  geometry.translate(xMid, yMid, zMid);

}
