<template>
  <div >
    <slider-round
        :id="id"
        :callback-on-checked="createXYZOrigin"
        :callback-on-unchecked="deleteXYZOriginFromScene"
      >XYZ Origin</slider-round>
  </div>
</template>

<script>
import SliderRound from "../../Inputs/SliderRound.vue";
import * as THREE from "three";
import {
  deleteFrom3DObjectByName
} from "../ModelManipulation/ReadProperties/SearchingFunctions";

export default {
  name: "show-origin",
  props:['scene', 'sceneRenderCallback'],
  components: {SliderRound},
  data(){
    return {
      id: 'xyz-origin',
      name: 'xyz-origin'
    }
  },
  methods: {
    createXYZOrigin(){
      this.$store.dispatch('showXYZOrigin')
    },
    deleteXYZOriginFromScene(){
      this.$store.dispatch('hideXYZOrigin')
    }

  }
}
</script>

<style scoped>

</style>
