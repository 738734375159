import * as THREE from "three";
import {PerspectiveCamera} from "three";


export class CameraConfig{

  constructor() {
    this.camera = this.cameraSetup();
  }

  cameraSetup(){
    let camera = new PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.25, 25000);
    camera.position.set( 3750, 0, -2000 );
    return camera
  }
}
