<template>

    <nav id="navbar">
      <div id="navbar-content" class="flex-row">
        <div id="lef-side">
          <div class="flex-row">

            <span><img src="KWLogo/logo_kwexpert_white.svg" id="KWLogo" /></span>
            <span
                   style="padding-left: 30px"
                   @click="showHideSidebar"
                   class="clicable-icon"
            >
              <font-awesome-icon icon="fa-solid fa-list-check" />
<!--              Options-->
            </span>
          </div>
        </div>
        <div id="right side">
          <span v-if="isLogged" class="padding" id='username'>Logged as: {{ user }} </span>
          <span v-if="isLogged" @click='onLogout' class="padding nav-item" id='logout'>logout</span>
  <!--        <span @click='scene' class="padding nav-item" id='scene'>scene</span>-->
          <developer-tools-menu v-if="developerTools">
          </developer-tools-menu>
        </div>
      </div>
    </nav>
</template>

<script>

import DeveloperToolsMenu from "../DeveloperTools/DeveloperToolsMenu.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'AppNavbar',
  components: {FontAwesomeIcon, DeveloperToolsMenu},
  data() {
    return {
    };
  },
  computed: {
    user() {
      return this.$store.state.authStore.user.name;
    },
    isLogged(){
      if (this.user==''){
        return false
      }else{
        return true
      }

    },
    sidebarVisible(){
      return this.$store.state.layoutStore.visibility.sidebar
    },
    menuButtonVisible(){
      return this.$store.state.layoutStore.visibility.menuButton
    },
    developerTools(){
      return this.$store.state.dev.developerTools
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout');
    },
    repositories() {
      this.$router.push('/table');
    },
    scene() {
      this.$router.push('/scene');
    },
    showHideSidebar(){
      this.$store.dispatch('showHideSidebar')
    }
  },

};
</script>

<style>
#navbar {
    /*text-align: left;*/
    padding:10px;
    background: var(--navbar-color);
    box-shadow: 2px 2px rgb(255, 255, 255);
    z-index: 99;
    position: fixed !important;
    width: 100%;
    margin-top:0px !important;
}

.nav-item{
    cursor: pointer;
}
.nav-item:hover {
  color: var(--hover-text-color)
}

.padding {
    padding:20px;
    color: white
}
#KWLogo{
  width: 50px;
  height: auto;
  display: flex;
  padding-left: 10px;

}
#navbar-content{
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  color: var(--dark-background-text);

}
</style>
