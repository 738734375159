
import { Options, Vue } from 'vue-class-component';
import HomePage from './pages/HomePage.vue';


@Options({
  components: {
    HomePage,
  },
})
export default class App extends Vue {}
