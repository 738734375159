import axios, { AxiosRequestConfig } from 'axios';

export interface AuthStoreStateInterface {
    endpoints: {
        api: string,
        login:string,
        cns:string
    }
    user: {
        isAuthenticated: boolean,
        name: string,
        idToken: string,
        accessToken: string
    },
}

const authStore = {
  state: () => ({
    endpoints: {
      api: '',
      login: '',
      cns: '',
    },
    user: {
      isAuthenticated: false,
      name: '',
      idToken: '',
      accessToken: '',
    },
  }),
  mutations: {
    setUser(state:any, payload:any) {
      state.user.name = payload.name;
      console.log(payload);
    },
    login(state:any, payload:any) {
      state.user.isAuthenticated = true;
      state.user.idToken = payload.idToken;
      state.user.accessToken = payload.accessToken;
      console.log('access: ', state.user);
    },
    resetState(state:any) {
      state.user.isAuthenticated = false;
      state.user.name = '';
      state.user.idToken = '';
      state.user.accessToken = '';
    },
  },
  getters: {

  },
  actions: {
    setUser(context:any, payload:any) {
      context.commit('setUser', payload);
    },
    login(context:any, payload:any) {
      context.commit('login', payload);
    },
    logout(context: any) {
      context.commit('resetState');
    },
  },
};

export default authStore;
