import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {DragControls} from "three/examples/jsm/controls/DragControls";
// import {ArcballControls} from "three/examples/jsm/controls/ArcballControls";


export class ControlsConfig{

  constructor() {
  }

  setupOrbitControls(camera, rendererDomElement){
    let controls = new OrbitControls( camera, rendererDomElement )
    controls.enableDamping=true
    controls.dampingFactor=0.1
    controls.update();
    this.orbitControls = controls
    return this.orbitControls
  }
  setupDragControls(object3D,camera, rendererDomElement){
    let controls = new DragControls(object3D, camera, rendererDomElement )
    this.dragControls = controls
    return this.dragControls
  }

}
