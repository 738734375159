import {Commit} from "vuex";

export interface AuthStoreStateInterface {
    visibility: {
        sidebar: boolean,
    }
}

const layoutStore = {
  state: () => ({
    visibility: {
      sidebar: true,
      menuButton:false
    },
  }),
  mutations: {
    showHideSidebar(state:any){
      state.visibility.sidebar = !state.visibility.sidebar
    },
    hideSidebar(state:any){
      state.visibility.sidebar = false
    },
    showSidebar(state:any){
      state.visibility.sidebar = true
    },
    showMenuButton(state:any){
      state.visibility.menuButton = true
    },
    hideMenuButton(state:any){
      state.visibility.menuButton = false
    },
  },
  getters: {

  },
  actions: {
    showHideSidebar({commit, state}: {commit:Commit, state: AuthStoreStateInterface}){
      commit('showHideSidebar')
    },
    showSidebar({commit, state}: {commit:Commit, state: AuthStoreStateInterface}){
      commit('showSidebar')
    },
    hideSidebar({commit, state}: {commit:Commit, state: AuthStoreStateInterface}){
      commit('hideSidebar')
    },

    showMenuButton({commit, state}: {commit:Commit, state: AuthStoreStateInterface}){
      commit('showMenuButton')
    },
    hideMenuButton({commit, state}: {commit:Commit, state: AuthStoreStateInterface}){
      commit('hideMenuButton')
    },
  },
};

export default layoutStore;
