
export const ASSEMBLY_NAME = "modelsAssembly"

export function deleteFrom3DObjectByName(name=undefined, object3D, event){
  object3D.remove(findInSceneByName(name, object3D));
  event();
}

export function findInSceneByName(name=undefined, scene) {
  if (name!=undefined) {
    for (const item in scene.children) {
      if (scene.children[item].name === name) {
        return scene.children[item];
      }
    }
  }
}

export function findModelInAssemblyOnScene( modelName="", object3D) {
  let group = findInSceneByName(ASSEMBLY_NAME, object3D);
  return findInSceneByName(modelName, group);

}
