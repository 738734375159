import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router-index';
import store from './store/store-index';
import { keycloackAuthorization } from './auth/keycloack-authorization';


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {
  faSliders, faRuler, faPuzzlePiece, faGear,faGroupArrowsRotate, faHandPointer, faListCheck, faGlobe,
  faSquare, faSquareCheck, faCircle, faCircleCheck, faCube, faCubes, faBars, faPause, faPlay, faArrowRotateLeft
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faSliders, faRuler, faPuzzlePiece, faGear, faGroupArrowsRotate, faHandPointer, faListCheck, faGlobe,
  faSquare, faSquareCheck, faCircle, faCircleCheck, faCube, faCubes, faBars, faListCheck, faPause, faPlay, faArrowRotateLeft
)


const app = createApp(App);
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)

const keycloak = false;

if (keycloak) {
  keycloackAuthorization(app, store);
} else {
  app.mount('#app');
}


//TODO.PS: HDRI env setup

