import {Group, Vector3, ArrowHelper, MeshPhongMaterial, Mesh, Box3} from "three";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import {
  setOriginInCenter
} from "../ModelManipulation/Positioning/PosFunctions";

const DimensionLine = {

  createDimension3DObject(object3D){
    const dimensionValues = this.setDimensionsValues(object3D)
    let dimensions = new Group()
    dimensions.name = "dimensions"

    for (let dim of dimensionValues){
      dimensions.add(this.createSingleDimension(dim))
    }
    return dimensions
  },

  setDimensionsValues(object3D){
    let boundingBox = new Box3();
    boundingBox.setFromObject(object3D);
    let dimensions = {
        'x': Math.round(boundingBox.max.x - boundingBox.min.x),
        'y': Math.round(boundingBox.max.y - boundingBox.min.y),
        'z': Math.round(boundingBox.max.z - boundingBox.min.z),
    }
    return [
      {
        'name': "x-dimension",
        'dir': {'x': 1, 'y':0, 'z':0},
        'origin': {'x': (boundingBox.min.x+boundingBox.max.x)/2, 'y':boundingBox.min.y, 'z':boundingBox.min.z-800},
        'value':dimensions.x
      },
      {
        'name': "y-dimension",
        'dir': {'x': 0, 'y':1, 'z':0},
        'origin': {'x': boundingBox.min.x, 'y':(boundingBox.min.y+boundingBox.max.y)/2, 'z':boundingBox.min.z-800},
        'value': dimensions.y
      },
      {
        'name': "z-dimension",
        'dir': {'x': 0, 'y':0, 'z':1},
        'origin': {'x': boundingBox.max.x+800, 'y':boundingBox.min.y, 'z':(boundingBox.min.z+boundingBox.max.z)/2},
        'value': dimensions.z
      },
    ]
  },

  createSingleDimension(params = {
    'name': "",
    'dir': {'x': 1, 'y': 0, 'z': 0},
    'origin': {'x': 0, 'y': 0, 'z': 0},
    'value': 0
  }) {
    // Create a group to integrate dimension
    let dimension = new Group();
    let origin = new Vector3(params.origin.x, params.origin.y, params.origin.z);

    dimension.name = params.name;
    dimension = this.dimensionArrows(dimension, params.dir, origin, params.value)

    // Set dimension direction
    let dimensionAxis = this.dimensionAxis(params.dir);
    // Add dimension description
    this.dimensionText(dimensionAxis, origin, dimension, params.value);

    return dimension
  },

  dimensionAxis(direction){
    if (direction.x == 1 && direction.y == 0 && direction.z == 0) {
      return 'x';
    }
    if (direction.x == 0 && direction.y == 1 && direction.z == 0) {
      return 'y';
    }
    if (direction.x == 0 && direction.y == 0 && direction.z == 1) {
      return 'z';
    }else{
      return ""
    }
  },

  dimensionArrows(dimension,direction, origin, value, arrowShape={color:0x000000, headLength:125, headWitth:50 }){
    /*
    param::dimension: Object3D for adding new arrows
    param::direction: object with shape {x:value, y:value, z:value}
    param::direction: object with shape {x:value, y:value, z:value}
    param::value:     Number value of the dimension
    param::arrowShape: optional if arrow shape should be changed

     */
    // Create dimension first arrow
    let dir = new Vector3(direction.x, direction.y, direction.z);
    let orig = new Vector3(origin.x, origin.y, origin.z);
    let firstArrow = new ArrowHelper(dir, orig, value / 2, arrowShape.color, arrowShape.headLength, arrowShape.headWitth);

    // Create dimension second arrow
    dir = new Vector3(direction.x * (-1), direction.y * (-1), direction.z * (-1));
    let secondArrow = new ArrowHelper(dir, orig, value / 2,  arrowShape.color, arrowShape.headLength, arrowShape.headWitth);
    dimension.add(firstArrow);
    dimension.add(secondArrow);

    return dimension
  },

  dimensionText(
    dimensionAxis = 'x',
    position = {'x': 0, 'y': 0, 'z': 0},
    object3D,
    value = ""
  ) {
    const loader = new FontLoader();
    loader.load('fonts/helvetiker_regular.typeface.json', (font) => {

      let materials = [
        new MeshPhongMaterial({color: 0xffffff, flatShading: true}), // front
        new MeshPhongMaterial({color: 0x000000}) // side
      ];

      const geometry = new TextGeometry(value + "", {
        font: font,
        size: 125,
        height: 2.5,
        curveSegments: 20,
        bevelThickness: 12.5,
        bevelSize: 5,
        bevelEnabled: true
      });

      geometry.computeBoundingBox();
      setOriginInCenter(geometry);

      const text = new Mesh(geometry, materials);
      text.position.x = position.x;
      text.position.y = position.y;
      text.position.z = position.z;

      if (dimensionAxis == 'x') {
        text.rotation.x = -Math.PI / 2;
        text.rotation.z = Math.PI;
        geometry.computeBoundingBox();
        let xHeight = (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        let yHeight = (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
        let zHeight = (geometry.boundingBox.max.z - geometry.boundingBox.min.z);
        text.position.z = text.position.z + zHeight * 5;
      }

      if (dimensionAxis == 'y') {
        text.rotation.y = Math.PI / 2;
        text.rotation.z = Math.PI / 2;
        geometry.computeBoundingBox();
        let zHeight = (geometry.boundingBox.max.z - geometry.boundingBox.min.z);
        text.position.z = text.position.z + zHeight * 5;
      }
      if (dimensionAxis == 'z') {
        text.rotation.x = -Math.PI / 2;
        text.rotation.z = Math.PI / 2;
        geometry.computeBoundingBox();
        let zHeight = (geometry.boundingBox.max.z - geometry.boundingBox.min.z);
        text.position.x = text.position.x - zHeight * 5;
      }

      object3D.add(text);
    })
  }
}

export {DimensionLine}
