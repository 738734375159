import { createStore } from 'vuex';
import authStore from './auth-store';
import modelsStore from './models-store'
import sceneStore from "@/store/scene-store";
import layoutStore from "@/store/layout-store";

const store = createStore({
  state() {
    return {
      dev: {
        developerTools: false,
      }
    };
  },
  modules: {
    authStore,
    modelsStore,
    sceneStore,
    layoutStore
  },

});

export default store;
