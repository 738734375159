// eslint-disable-next-line import/prefer-default-export
export function keycloakLogout(keycloak, store, LOGOUT_REDIRECT_URL) {
  if ((keycloak.token && keycloak.idToken) !== '' && (keycloak.idToken !== '')) {
    const logoutOptions = { redirectUri: LOGOUT_REDIRECT_URL };

    if (!store.state.authStore.user.isAuthenticated) {
      keycloak.logout(logoutOptions).then((success) => {
        console.log('--> log: logout success ', success);
      }).catch((error) => {
        console.log('--> log: logout error ', error);
      });
    }
  }
}
