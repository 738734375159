<template>
  <div class="flex-row" style="justify-content: space-between">
    <div>
      <label for="id">
        <h4> <slot></slot></h4>
      </label>
    </div>
    <div style="align-items: center; display: flex; justify-content: center">
      <label class="switch" >
        <input type="checkbox" id="id" @click="onClick">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider-round",
  props: ['id', 'callbackOnChecked','callbackOnUnchecked' ],
  methods: {
    onClick(source) {
      if (source.target.checked) {
        this.callbackOnChecked();
      } else {
        this.callbackOnUnchecked();
      }
    },
  }
}
</script>

<style scoped>
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: var(--hover-text-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--hover-text-color);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
