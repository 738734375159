<template>

  <navbar id='navbar' style="z-index: 4"></navbar>
  <router-view></router-view>

</template>

<script>
import Navbar from '../components/NavigationComponents/Navbar.vue';

export default {
  name: 'home',
  components: {
    Navbar,
  },
  data() {
    return {
    };
  },
};
</script>
<style>
#app {
  --background-dark-color: gray;
  --background-light-color: white;
  --background-focus-color: #5b5b5b;

  --navbar-color:var(--background-dark-color);
  --sidebar-color: var(--background-dark-color);

  --dark-background-text: white;
  --light-background-text:black;

  --hover-text-color: #0372A7;
  --base-color-dark: #103042;

  --shadow-color: rgb(255, 255, 255);
  --shadow-dark-color: rgba(0,0,0,0.2);
  --shadow-darker-color: rgba(0,0,0,0.5);
  --shadow-darkest-color: rgba(0,0,0,0.8);

  --font:Avenir, Helvetica, Arial, sans-serif;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-background-text);

  display: flex;
}

#app, a {
  color: var(--dark-background-text);
  text-decoration:none;
}

#app, a:hover{
  color: var(--hover-text-color)
}

body {
  margin:0px !important;
  margin-top:0px !important;
}

textarea, input, select, button, input[type=file] {
  color:var(--light-background-text);
  font-family: var(--font);
  background: var(--dark-background-text);
  border-radius: 5px;
  border: solid 3px var(--dark-background-text);
}
input:focus{
  outline: none;
  box-shadow: 0px 0px 3px 1px var(--hover-text-color);

}

button{
  cursor: pointer;
  box-shadow:  0px 4px 3px var(--shadow-dark-color);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

}
button:hover{
  box-shadow:  0px 4px 3px var(--shadow-darker-color);
}
label{
  font-weight: bold;
  padding-bottom: 2px;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-col{
  display: flex;
  flex-direction: column;
}

.clicable-icon{
  cursor: pointer;
}
.clicable-icon:hover{
  color: var(--hover-text-color)
}
.activeIcon{
  color: var(--hover-text-color)
}

h1, h2, h3, h4, h5{
  margin-top: 8px;
  margin-bottom: 8px ;
}

</style>

