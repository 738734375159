import {Group, Vector3, ArrowHelper} from "three";

export const XYZ_ORIGIN_NAME = 'XYZ0-origin'

export class SceneOrigin{

  constructor() {
    this.createXYZOrigin()
  }

  createXYZOrigin() {

    let origin = new Group();
    origin.name = XYZ_ORIGIN_NAME

    let dir = new Vector3(1, 0, 0);
    //normalize the direction vector (convert to vector of length 1)
    dir.normalize();
    let AxisX = new Vector3(0, 0, 0);
    let length = 1250;
    let hex = 0xff0000;
    const arrowHelperX = new ArrowHelper(dir, AxisX, length, hex);
    origin.add(arrowHelperX);

    dir = new Vector3(0, 1, 0);
    //normalize the direction vector (convert to vector of length 1)
    dir.normalize();
    let AxisY = new Vector3(0, 0, 0);
    hex = 0x00ff00;
    const arrowHelperY = new ArrowHelper(dir, AxisY, length, hex);
    origin.add(arrowHelperY);

    dir = new Vector3(0, 0, 1);
    //normalize the direction vector (convert to vector of length 1)
    dir.normalize();
    let AxisZ = new Vector3(0, 0, 0);
    hex = 0x0000ff;
    const arrowHelperZ = new ArrowHelper(dir, AxisZ, length, hex);
    origin.add(arrowHelperZ);

    origin.visible = false

    this.xyzOrigin = origin;
  }
}
