import THREE from "three";
import {ShadowMapViewer} from "three/examples/jsm/utils/ShadowMapViewer";
import {AmbientLight, SpotLight, BoxGeometry, ShadowMaterial, Mesh, DirectionalLight} from "three";


export class ModelShadowConfig{

  spotLight = new SpotLight( 0xffffff );
  directionalLight = new DirectionalLight()

  ground = {}

  constructor(shadowVerticalAlignment) {
    // this.spotLightSetup()
    this.directionalLightSetup()
    this.groundSetup(shadowVerticalAlignment);
    window.addEventListener('resize', ()=>{
      this.resizeShadowMapViewers();
      this.spotLightShadowMapViewer.updateForWindowResize();
    })
  }

  getShadow(){
    return {
      // spotLight: this.spotLight,
      spotLight: this.directionalLight,
      ground: this.ground
    }
  }

  spotLightSetup(){
    this.spotLight.name = 'Spot Light';
    this.spotLight.angle = Math.PI / 5;
    this.spotLight.penumbra = 0.3;
    this.spotLight.position.set( 1000, 5000, -1000 );
    this.spotLight.castShadow = true;
    this.spotLight.shadow.camera.near = 5000;
    this.spotLight.shadow.camera.far = 10000;
    this.spotLight.shadow.mapSize.width = 1024;
    this.spotLight.shadow.mapSize.height = 1024;
    this.spotLight.shadow.radius = 20;
    this.spotLight.shadow.blurSamples=25;


    this.spotLightShadowMapViewer = new ShadowMapViewer( this.spotLight );
    this.resizeShadowMapViewers();
  }
  directionalLightSetup(){
    this.directionalLight.position.set( 1000, 5000, -1000 )
    this.directionalLight.castShadow = true
    this.directionalLight.shadow.mapSize.width = 1024
    this.directionalLight.shadow.mapSize.height = 1024
    this.directionalLight.shadow.camera.near = 5000
    this.directionalLight.shadow.camera.far = 25000
    this.directionalLight.shadow.camera.left = -100000
    this.directionalLight.shadow.camera.right = 100000
    this.directionalLight.shadow.camera.top = 100000
    this.directionalLight.shadow.camera.bottom = -100000
    this.directionalLight.shadow.radius = 5
    this.directionalLight.shadow.blurSamples = 25

    this.spotLightShadowMapViewer = new ShadowMapViewer( this.directionalLight );
    this.resizeShadowMapViewers();
  }


  groundSetup(shadowVerticalAlignment){
      const geometry = new BoxGeometry( 25000, 0.001, 25000 );
      const material = new ShadowMaterial( {
        opacity:0.8,
      } );
      material.transparent = true;

      const ground = new Mesh( geometry, material );
      ground.position.y=shadowVerticalAlignment;
      ground.scale.multiplyScalar( 3 );
      ground.name = 'Floor-shadow-plane'
      ground.castShadow = false;
      ground.receiveShadow = true;

      this.ground = ground
  }

  resizeShadowMapViewers(){
    const size = window.innerWidth * 37.5;
    this.spotLightShadowMapViewer.size.set( size, size );
    this.spotLightShadowMapViewer.position.set( size + 5000, 2500 );
  }
}
