// import axios, { AxiosRequestConfig } from 'axios';
// import * as THREE from 'three'

import {ActionContext, Commit} from "vuex";
import {fetchModel} from "@/components/ThreeDCanvas/ModelManipulation/ReadProperties/MockModelsList";
import {Scene, PerspectiveCamera, WebGLRenderer, Object3D, Box3} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {SceneConfig} from "@/components/ThreeDCanvas/SceneConfig/SceneConfig";
import {CameraConfig} from "@/components/ThreeDCanvas/SceneConfig/CameraConfig";
import {RendererConfig} from "@/components/ThreeDCanvas/SceneConfig/RendererConfig";
import {LightningConfig} from "@/components/ThreeDCanvas/SceneConfig/LightningConfig";
import {nextTick, toRaw} from 'vue'
import {SceneOrigin, XYZ_ORIGIN_NAME} from "@/components/ThreeDCanvas/SceneConfig/SceneOrigin";
import {
  ASSEMBLY_NAME,
  findInSceneByName
} from "@/components/ThreeDCanvas/ModelManipulation/ReadProperties/SearchingFunctions";
import {ControlsConfig} from "@/components/ThreeDCanvas/SceneConfig/ControlsConfig";
import {Group} from "three";
import {ModelShadowConfig} from "@/components/ThreeDCanvas/SceneConfig/ModelShadowConfig";
import {SpotLightHelper} from "three";
import {DimensionLine} from "@/components/ThreeDCanvas/HelperModels/DimensionLines";

export interface LightningInterface{
  ambientLight: Object,
  spotLight: Object,
  spotAmbientLights: Object,
  ground: Object

}

export interface SceneStoreInterface {
  scene: Scene,
  camera: PerspectiveCamera,
  renderer: WebGLRenderer,
  lightning: LightningInterface,
  shadow:Object,
  controls: Object
}

const sceneStore = {
  state: () => ({
    scene: {},
    camera: {},
    renderer: {},
    lightning: {},
    shadow:{},
    controls:{
      dragControls:{},
      orbitControls:{},
      orbitControlsEnabled:false,
      dragControlsEnabled:false,
    },

  }),

  mutations: {
    createScene(state:any){
      state.scene = new SceneConfig().sceneSetup();
    },
    createCamera(state:any){
      state.camera = new CameraConfig().camera;
    },
    createRenderer(state:any){
      state.renderer = new RendererConfig().renderer;
    },
    createLightning(state:any){
      state.lightning = new LightningConfig().completeLightning();
    },
    createShadow(state:any, shadowVerticalAlignment:Number){
      state.shadow = new ModelShadowConfig(shadowVerticalAlignment).getShadow();
    },
    changeShadowPosition(state:any, shadowVerticalAlignment:Number){
      const ground = toRaw(state.shadow.ground)
      ground.position.set(0, shadowVerticalAlignment,0)
      state.shadow.ground = ground
    },

    createXYZOrigin(state:any) {
      let scene = toRaw(state.scene);
      scene.add(new SceneOrigin().xyzOrigin)
      state.scene = scene
    },
    showXYZOrigin(state:any) {
      let scene = toRaw(state.scene);
      findInSceneByName(XYZ_ORIGIN_NAME, scene).visible =true
      state.scene = scene
    },
    hideXYZOrigin(state:any) {
      let scene = toRaw(state.scene);
      findInSceneByName(XYZ_ORIGIN_NAME, scene).visible =false
      state.scene = scene
    },

    addCameraToScene(state:any){
      state.scene = state.scene.add(state.camera)
    },
    addLightningToScene(state:any){
      let scene = state.scene
      scene.add(state.lightning.ambientLight)
      scene.add(state.lightning.spotLight)
      scene.add(state.lightning.ground)
      for (let light of state.lightning.spotAmbientLights){
        scene.add(light)
      }
      state.scene = scene
    },
    addShadowToScene(state:any){
      let scene = state.scene
      scene.add(state.shadow.spotLight)
      scene.add(state.shadow.ground)
      state.scene = scene
    },
    addAssemblyGroupToScene(state:any){
      state.scene = state.scene.add(state.assembly.models)
    },

    setCameraAspect(state:any) {
      let camera = toRaw(state.camera)
      camera.aspect = window.innerWidth / window.innerHeight;
      state.camera = camera
    },
    setRendererSize(state:any){
      let renderer = toRaw(state.renderer)
      renderer.setSize( window.innerWidth, window.innerHeight )
      state.renderer=renderer
    },

    //========== C O N T R O L S
    // O r b i t
    setOrbitControls(state:any){
      let controls = new ControlsConfig().setupOrbitControls(state.camera, state.renderer.domElement);
      state.controls.orbitControlsEnabled = true
      state.controls.orbitControls = controls
    },
    disableOrbitControls(state:any){
      let controls = toRaw(state.controls.orbitControls)
      controls.enabled=false

      state.controls.orbitControlsEnabled = false
      state.controls.orbitControls = controls
    },
    enableOrbitControls(state:any){
      let controls = toRaw(state.controls.orbitControls)
      controls.enabled=true
      state.controls.orbitControlsEnabled = true
      state.controls.orbitControls = controls
    },

    // D r a g
    setDragControls(state:any, payload:Object3D){
      let controls = new ControlsConfig().setupDragControls(payload.children, state.camera, state.renderer.domElement );
      // state.controls.orbitControls = false

      controls.deactivate()
      state.controls.dragControlsEnabled = false
      state.controls.dragControls = controls
    },
    disableDragControls(state:any){
      let controls = toRaw(state.controls.dragControls)
      controls.deactivate()

      state.controls.dragControlsEnabled = false
      state.controls.dragControls = controls
    },
    enableDragControls(state:any){
      let controls = toRaw(state.controls.dragControls)
      controls.activate()
      state.controls.dragControlsEnabled = true
      state.controls.dragControls = controls
    },


    //======= U P D A T I N G
    updateCameraProjectionMatrix(state:any){
      let camera = toRaw(state.camera)
      camera.updateProjectionMatrix()
      state.camera= camera
    },
    rendererRender(state:any){
      let renderer = toRaw(state.renderer)
      renderer.render(toRaw(state.scene), toRaw(state.camera))
      state.renderer = renderer
    },
    removeObjectFromScene(state:any, payload:Object3D){
      let scene = toRaw(state.scene)
      scene.remove(payload)
      state.scene = scene
    },
    removeAssemblyFromScene(state:any){
      let scene = toRaw(state.scene)
      scene.remove(scene.getObjectByName(ASSEMBLY_NAME))
      state.scene = scene
    },
    addObjectToSceneAssembly(state:any, payload:Object3D) {
      let assembly = toRaw(state.scene)
      assembly.add(payload)
      // state.assembly.models =payload
      state.scene = assembly

    },
    addHelpersToScene(state:any){
      let scene = toRaw(state.scene)
      const spotLightHelper = new SpotLightHelper( state.shadow.spotLight );
      scene.add( spotLightHelper );
      state.scene = scene
    },

    // hide show
    hideShadow(state:any){
      let shadow = toRaw(state.shadow)
      shadow.ground.visible = false
      state.shadow = shadow
    },
    showShadow(state:any){
      let shadow = toRaw(state.shadow)
      shadow.ground.visible = true
      state.shadow = shadow
    },

  },
  getters: {

  },
  actions: {
    createScene ({commit, state}: {commit:Commit, state: SceneStoreInterface}){
      commit('createScene')
    },
    createCamera ({commit, state}: {commit:Commit, state: SceneStoreInterface}){
      commit('createCamera')
    },
    createRenderer ({commit, state}: {commit:Commit, state: SceneStoreInterface}){
      commit('createRenderer')
    },

    setupScene({commit, state}: {commit:Commit, state: SceneStoreInterface}){
      commit('createRenderer');
      commit('createCamera');


      commit('createScene');
      commit('addCameraToScene');
      commit('createXYZOrigin');
      commit('createAssembly');

      commit('createShadow', -1300);
      commit('addShadowToScene');
      // commit('addHelpersToScene')

      commit('setOrbitControls');
      // commit('disableOrbitControls')
      commit('setDragControls', new Group());


      setTimeout(()=>{
        commit('updateCameraProjectionMatrix');
        commit('rendererRender')
      }, 1000)
    },

    rendererRender({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('updateCameraProjectionMatrix');
      commit('rendererRender')
    },

    setCameraAspect({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('setCameraAspect')
    },
    setRendererSize({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('setRendererSize')
    },

    windowResized({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('setCameraAspect')
      commit('setRendererSize')
      commit('addCameraToScene');

      commit('updateCameraProjectionMatrix');
      commit('rendererRender')

    },
    showXYZOrigin({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('showXYZOrigin')
      commit('rendererRender')
    },
    hideXYZOrigin({commit, state}: {commit:Commit, state: SceneStoreInterface}){
      commit('hideXYZOrigin')
      commit('rendererRender')

    },
    removeObjectFromScene({commit, state}: {commit:Commit, state: SceneStoreInterface}, payload:Object3D) {
      commit('removeObjectFromScene', payload)
    },

    changeShadowPosition({commit, state}: {commit:Commit, state: SceneStoreInterface}, shadowVerticalAlignment:Number){
      commit('changeShadowPosition', shadowVerticalAlignment)
    },

    disableDragControls({commit, state}: {commit:Commit, state: SceneStoreInterface}, payload:Object3D) {
      commit('disableDragControls')
      commit('rendererRender')

    },
    enableDragControls({commit, state}: {commit:Commit, state: SceneStoreInterface}, payload:Object3D) {
      commit('enableDragControls')
      commit('rendererRender')

    },
    disableOrbitControls({commit, state}: {commit:Commit, state: SceneStoreInterface}, payload:Object3D) {
      commit('disableOrbitControls')
      commit('rendererRender')

    },
    enableOrbitControls({commit, state}: {commit:Commit, state: SceneStoreInterface}, payload:Object3D) {
      commit('enableOrbitControls')
      commit('rendererRender')

    },

    hideShadow({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('hideShadow')
      commit('rendererRender')
    },
    showShadow({commit, state}: {commit:Commit, state: SceneStoreInterface}) {
      commit('showShadow')
      commit('rendererRender')
    },

  },
};

export default sceneStore;


//TODO.PS: implement reload scene after model change
