<template>
  <div id="devtools">
    <div class="clicable-icon" @click="showMenu=!showMenu">
      <font-awesome-icon icon="fa-solid fa-gear"/>
    </div>
    <div id="devtools-menu" class="flex-col" v-if="showMenu">
      <div>
        <show-origin
          :scene="scene"
        ></show-origin>
      </div>
      <div>
        <button @click="logSceneObject">SceneObj</button>
      </div>
      <div style="padding-top: 10px">
        <button @click="logStore">StoreState</button>
      </div>
      <div>
        <button @click="showCameraPosition">Camera position</button>
      </div>
      <div id="controls-menu" style="padding-top: 10px">
        <div  class="flex-row" style="justify-content: space-between">
          <div ><h4>Orbit controls</h4></div>
          <div
            style="align-items: center; display: flex; "
            @click="toggleOrbitControls"
          >
            <font-awesome-icon :class="{activeIcon:orbitControlsEnabled}" icon="fa-solid fa-group-arrows-rotate" class="clicable-icon"/>
          </div>
        </div>
        <div  class="flex-row" style="justify-content: space-between">
          <div ><h4>Drag controls</h4></div>
          <div
            style="align-items: center; display: flex; "
            @click="toggleDragControls"
          >
            <font-awesome-icon :class="{activeIcon:dragControlsEnabled}" icon="fa-solid fa-hand-pointer" class="clicable-icon"/>
          </div>
        </div>
<!--        <div  class="flex-row" style="justify-content: space-between">-->
<!--          <div ><h4>Arcball controls</h4></div>-->
<!--          <div-->
<!--            style="align-items: center; display: flex; "-->
<!--            @click="toggleArcballControls"-->
<!--          >-->
<!--            <font-awesome-icon :class="{activeIcon:arcballControlsEnabled}" icon="fa-solid fa-globe" class="clicable-icon"/>-->
<!--          </div>-->
<!--        </div>-->
      </div>


    </div>
  </div>
</template>

<script>
import ShowOrigin from "../ThreeDCanvas/Helpers/ShowOrigin.vue";
import {toRaw} from "vue";
export default {
  name: "DeveloperToolsMenu.vue",
  components: {ShowOrigin},
  data(){
    return{
      showMenu: false,
    }
  },
  methods:{
    logSceneObject(){
      console.log(this.scene);
    },
    logStore(){
      console.log(toRaw(this.$store.state))
    },
    toggleDragControls(){
      if (this.dragControlsEnabled){
        this.$store.dispatch('disableDragControls')
      }else{
        this.$store.dispatch('enableDragControls')
      }
    },
    toggleOrbitControls(){
      if (this.orbitControlsEnabled){
        this.$store.dispatch('disableOrbitControls')
      }else{
        this.$store.dispatch('enableOrbitControls')
      }
    },
    // toggleArcballControls(){
    //   if (this.arcballControlsEnabled){
    //     this.$store.dispatch('disableArcballControls')
    //   }else{
    //     this.$store.dispatch('enableArcballControls')
    //   }
    // }
    showCameraPosition(){
      console.log(this.camera.position)
    }
  },
  computed:{
    scene(){
      return toRaw(this.$store.state.sceneStore.scene)
    },
    orbitControlsSelected(){
      return this.$store.state.sceneStore.controls.orbitControls
    },
    dragControls(){
      return this.$store.state.sceneStore.controls.dragControls
    },
    dragControlsEnabled(){
      return this.$store.state.sceneStore.controls.dragControlsEnabled
    },
    orbitControls(){
      return this.$store.state.sceneStore.controls.orbitControls
    },
    orbitControlsEnabled(){
      return this.$store.state.sceneStore.controls.orbitControlsEnabled
    },
    camera(){
      return this.$store.state.sceneStore.camera
    }
    // arcballControls(){
    //   return this.$store.state.sceneStore.controls.arcballControls
    // },
    // arcballControlsEnabled(){
    //   return this.$store.state.sceneStore.controls.arcballControlsEnabled
    // }

  }
}
</script>

<style scoped>
#devtools{
  z-index: 1;
}
#devtools-menu{
  margin-top: 20px;
  padding:20px;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(3px);

  position: fixed;
  right: 10px;
  min-width: 150px;
  border-radius: 5px ;
}

</style>
