import { createRouter, createWebHistory } from 'vue-router';

const routes = [

  {
    path: '/home',
    component: () => import('../pages/HomePage.vue'),
    children: [
      { path: '/', component: () => import('../pages/ScenePage.vue') },

      { path: '/:pathMatch(.*)*', component: () => import('../pages/NotFoundPage.vue') },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

});

export default router;
