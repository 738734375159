import Keycloak from 'keycloak-js';
import { keycloakLogout } from './keycloak-logout';

const LOGIN_PAGE_URL = 'http://127.0.0.1:8080/auth';
const LOGOUT_REDIRECT_URL = 'http://127.0.0.1:3000/';
const REALM = 'vueapprealm';
const CLIENT_ID = 'vue-interface';
const ON_LOAD = 'login-required';

const initOptions = {
  url: LOGIN_PAGE_URL,
  realm: REALM,
  clientId: CLIENT_ID,
  onLoad: ON_LOAD,
};

export function authResult(keycloak, store, app, auth) {
  if (!auth) {
    window.location.reload();
  } else {
    app.mount('#app');
    store.dispatch('setUser', { name: keycloak.tokenParsed.preferred_username });

    const payload = {
      idToken: keycloak.idToken,
      accessToken: keycloak.token,
    };
    store.dispatch('login', payload);
  }
}

export function tokenRefresh(keycloak, store, refreshed) {
  if (refreshed) {
    console.log(`Token refreshed${refreshed}`);
    const payload = {
      idToken: refreshed.idToken,
      accessToken: refreshed.token,
    };
    store.dispatch('login', payload);
  } else {
    // console.log('Token not refreshed, valid for '
    // + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000)
    // + ' seconds');
  }
}

export function keycloackAuthorization(app, store) {
  const keycloak = Keycloak(initOptions);

  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
    authResult(keycloak, store, app, auth);

    setInterval(() => {
      keycloakLogout(keycloak, store, LOGOUT_REDIRECT_URL);

      keycloak.updateToken(70).then((refreshed) => {
        tokenRefresh(keycloak, store, refreshed);
      }).catch(() => {
        console.log('Failed to refresh token');
      });
    }, 1000);
  }).catch(() => {
    console.log('Authenticated Failed');
  });
}
