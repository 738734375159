import THREE, {EquirectangularReflectionMapping} from "three";
import {toRaw} from "vue";
import {Scene, TextureLoader, Fog} from "three";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";


export class SceneConfig{
  STATIC_SCENE_BACKGROUND_URL = 'background/empty-factory-interior-background.jpg'
  HDR_SCENE_BACKGROUND_URL = 'background/HDR/HallHDRI.hdr'

  constructor() {
  }

  sceneSetup(){
    let scene = new Scene();

    scene.matrixAutoUpdate = true;
    scene.fog = new Fog( 0xa0a0a0, 2500, 22500 );

    return this.hdrEnvironmentalLoad(scene);
  };

  hdrEnvironmentalLoad(scene){
    const loader = new RGBELoader();

    loader.load(this.HDR_SCENE_BACKGROUND_URL, (texture)=> {
      texture.mapping = EquirectangularReflectionMapping;
      scene.environment = texture;
      scene.background = texture;
    })
    return scene
  }

  staticBackground(scene){
    scene.background = new TextureLoader().load(this.STATIC_SCENE_BACKGROUND_URL);

    return scene
  }

}
