// import axios, { AxiosRequestConfig } from 'axios';
// import * as THREE from 'three'

import {ActionContext, Commit} from "vuex";
import {Group, Object3D, Box3, AnimationMixer, Clock} from 'three'
import {toRaw} from "vue";
import {DimensionLine} from "@/components/ThreeDCanvas/HelperModels/DimensionLines";
import {
  ASSEMBLY_NAME
} from "@/components/ThreeDCanvas/ModelManipulation/ReadProperties/SearchingFunctions";


export interface ModelsStoreStateInterface {
  assembly: {
    modelScale: Number,
    models: Group,
    dimensions: Object3D,
    loadedToScene:Boolean,
    animations:{
      animationMixer:AnimationMixer,
      clips:any,
      clock:Clock,
      animationSpeedCoeff:Number
    }
  }
}
export const modelsStoreInitialState = {
  assembly: {
    modelScale: 1.0,
    models:{},
    dimensions: {},
    loadedToScene:false,
    animations:{
      animationMixer:{},
      clips:{},
      clock:{},
      animationSpeedCoeff:0.1
    }

  },
}

const modelsStore = {
  state: () => (modelsStoreInitialState),

  mutations: {

    resetModelStoreToInitialState(state:any, payload:any){
      state=modelsStoreInitialState
    },
    setModelsGroupObject(state:any, payload:Group){
      state.assembly.models = payload;
    },
    createAssembly(state:any){
        let assemblyGroup = new Group()
        assemblyGroup.name=ASSEMBLY_NAME
        state.assembly.models = assemblyGroup
    },
    createDimensions(state:any){
      let assembly = toRaw(state.assembly.models)
      let dimensions = DimensionLine.createDimension3DObject(assembly)
      dimensions.visible = false
      assembly.add(dimensions)
      state.assembly.dimensions = dimensions
      state.assembly.models = assembly
    },
    showDimensions(state:any){
      let dimensions = toRaw(state.assembly.dimensions)
      dimensions.visible = true
      state.assembly.dimensions = dimensions
    },
    hideDimensions(state:any){
      let dimensions = toRaw(state.assembly.dimensions)
      dimensions.visible = false
      state.assembly.dimensions = dimensions
    },

    modelsLoadedToScene(state:any){
      state.assembly.loadedToScene=true
    },
    modelsRemovedFromScene(state:any){
      state.assembly.loadedToScene=false
    },
  //  Animations
    configureAnimationMixer(state:any){
      let mesh = state.assembly.models.children[0]
      state.assembly.animations.clock = new Clock()
      state.assembly.animations.clips = mesh.animations
      state.assembly.animations.animationMixer = new AnimationMixer(mesh)
    },

    animateModel(state:any){

    }

  },

  actions: {

    resetModelStoreToInitialState({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('resetModelStoreToInitialState')
    },
    createDimensions({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}, payload:Object3D){
      commit('createDimensions')
    },
    showDimensions({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('showDimensions')
      commit('rendererRender')
    },
    hideDimensions({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('hideDimensions')
      commit('rendererRender')
    },
    addObjectToSceneAssembly({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}, payload:Object3D) {
      payload.name = ASSEMBLY_NAME
      commit('removeAssemblyFromScene')
      commit('addObjectToSceneAssembly', payload);
      commit('setModelsGroupObject', payload);

      commit('setDragControls', payload);
      commit('enableOrbitControls');
      setTimeout(()=>{
        commit('rendererRender')

      },2000)

    },
    modelsRemovedFromScene({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('modelsRemovedFromScene')
    },
    modelsLoadedToScene({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('modelsLoadedToScene')
    },
    configureAnimationMixer({commit, state}: {commit:Commit, state: ModelsStoreStateInterface}){
      commit('configureAnimationMixer')
    }

  },
};

export default modelsStore;


